body {
   margin: 0;
   font-size: 16px;
   font-family:Helvetica,Arial,sans-serif;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.map:-webkit-full-screen {
   height: 100%;
   margin: 0;
}
.map:-ms-fullscreen {
   height: 100%;
}
.map:fullscreen {
   height: 100%;
}



#logo {
   position: absolute;
   left: 0;
   bottom: 0;
   width: 3%;
   height: auto;
}
#logo img{
   width: 100%;
}

#help-button{
   top: 2.5em;
   right: 0.5em;
}
.ol-popup {
  position: absolute;
  background-color: white;
  font-family:Helvetica,Arial,sans-serif;
  font-size:13px;
  font-style:normal;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 180px;
  display: table;
}

.ol-popup h1{
    margin: 0 0 0.5em 0;
    font-size: 1.2em;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
   font-size: 16px;
   font-weight: 700;
   text-decoration-line: none;
   color: rgb(195, 195, 195);
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 4px 4px 0 0;
}
.ol-popup-closer:after {
  content: "x";
}
.ol-hover {
  position: absolute;
  background-color: grey;
  font-family:Verdana,Helvetica,Arial,sans-serif;
  font-size:0px;
  font-style:normal;
  color: white;
  text-align: center;
  display:table;
  width: auto;
  /*line-height:1.1;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));*/
  padding: 2px 3px 3px 3px;
  border-radius: 3px;
  bottom: 10px;
  left: -10px;
  min-width: 80px;
  margin: 0px;
}
.ol-hover p{
   font-size: 13px;
   word-wrap: unset;
   display: contents;
} 


/*Help popups
*/
.text-container {
  visibility:hidden;
  position:absolute;
  width:15%;
  height:auto;
  padding:2em; 
  right: 0;
  top: 0;
  /*left: auto;*/
  left: unset;
  font-size: 90%
}
.text-container.active{
visibility:visible;
}

.content {
visibility:hidden;
}
.content.active {
visibility:visible;
}

.text-container td{
   padding-left: 1em;
   padding-top: 0.3em;
} 
.text-container .legende-term {
   width: 3em;
   vertical-align: top;
}

.text-container hr {
   height: 1em;
   border: 0;
   margin: 0;
}
  
.text-container p{
   clear: both;
   display: inline-block;
   margin: .3em 0;
}
.text-container p.small{
   font-size: 80%;
}

